// App.js
import React, { useState } from 'react';
import {Header} from 'rups-components';
import ServiceList from './ServiceList';

const App = () => {
  const [services, setServices] = useState(require('./services.json'));

  return (
    <div className="App">
      <Header title={"rups.cloud"}></Header>
      <ServiceList services={services} />
    </div>
  );
}

export default App;