// ServiceButton.js
import React from 'react';
import './ServiceButton.css';

const ServiceButton = ({ serviceName, url, icon }) => (
  // <button className="service-button" onClick={() => window.location.href = url}>{serviceName}</button>
  // if there is an icon show the icon above the name
  <button className="service-button" onClick={() => window.location .href = url}>
    {icon && <img src={icon} alt={serviceName} />}
    {serviceName}
  </button>
);

export default ServiceButton;