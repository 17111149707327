// ServiceList.js
import React from 'react';
import ServiceButton from './ServiceButton';
import './ServiceList.css';
import services from './services.json';

const ServiceList = () => (
  <div className="service-list">
    {services.services.map(service => <ServiceButton key={service.name} serviceName={service.name} url={service.url} icon={service.icon} />)}
  </div>
);

export default ServiceList;